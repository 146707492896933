@font-face {
  font-family: 'PlusJakartaSans';
  src: url(./assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url(./assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url(./assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.ttf) format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url(./assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url(./assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBold.ttf) format("opentype");
  font-weight: 800;
}

* {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: 'PlusJakartaSans';
  transition: background-color 0.3s, color 0.3s;
}
.default-page {
  overflow-x: hidden;
  padding: 0 80px;
  padding-top: 35px;
  // background-image: url(./assets/images/whale.png);
  background: linear-gradient(107.56deg, #2A4874 0%, #222942 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 100%;
  height: 100vh;
  position: relative;
  min-height: 650px;
}
h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 125%;
}
h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 125%;
}
h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 158%;
}

p {
  font-weight: 600;
  font-size: 18px;
  line-height: 127%;
}

input {
  font-size: 16px;
  line-height: 127%;
  font-weight: 400;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  color:#2B90CC;
  text-decoration: none;
  &:hover {
    color: #3DA7E7;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}


@media screen and (max-width: 1600px) {
  p, a {
    font-size: 16px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4  {
    font-size: 20px;
  }
}

@media screen and (max-width: 850px) {
  .default-page {
    padding: 20px;
  }
}